import React, { useState, useRef } from "react";
import "./CaseMgmtOurServiceSection.css";
import home_chess from "../../../../assets/icons/home_chess.svg";

import up_icon from "../../../../assets/icons/our_service_section_left_side_up_icon.svg";
import down_icon from "../../../../assets/icons/our_service_section_left_side_down_icon.svg";

const services = {
  "Cash Transit & Logistics": {
    title: "Cash Transit & Logistics",
    content: (
      <>
        <p>
          We are solely responsible for secure cash conveyance - moving and
          sorting a lot of cash currency through modernized infrastructure and
          ensuring a comprehensive security protocol that manages physical and
          electronic setup.
        </p>
      </>
    ),
  },
  "Cash Processing": {
    title: "Cash Processing",
    content: (
      <>
        <p>
          Our well-trained security personals are involved in effective cash
          processing techniques that keep in mind the security and accuracy
          aspect at the tip. At our cash management centres, we ensure a precise
          and error-free processing with complete security so that there is no
          risk involved.
        </p>
      </>
    ),
  },
  "Cash Counters": {
    title: "Cash Counters",
    content: (
      <>
        <p>
          We have cash counting machines that are effective and can manage
          counting of notes in an accurate manner. These counters keep a track
          of the money involved and can be leveraged during any cash management
          process.
        </p>
      </>
    ),
  },
  "Cash Vaulting": {
    title: "Cash Vaulting",
    content: (
      <>
        <p>
          This is our safe and secure way of picking up and delivering cash,
          with a powerful surveillance system in place and our well-armed
          personnel ensuring complete security. Our vaults are safeguarded
          against any malicious attack, through our stringent processes.
        </p>
      </>
    ),
  },
  "Atm Cassette Swap": {
    title: "Atm Cassette Swap",
    content: (
      <>
        <p>
          We implement the lockable system – ATM Cassette Swap in which the
          entire manual process of cash replenishment is replaces by this
          innovative mechanism. This system is more secure, quality oriented and
          possesses simplified way of managing cash.
        </p>
      </>
    ),
  },
  "Cash Recyclers": {
    title: "Cash Recyclers",
    content: (
      <>
        <p>
          Adding one to the list, cash recyclers are self-service terminals that
          empower users to withdraw cash and deposit cash, without any bank
          representative present at that time. We ensure complete security and
          adherence to industry standards.
        </p>
      </>
    ),
  },
  "Bullion Transportation, Vaulting & Cearance": {
    title: "Bullion Transportation, Vaulting & Cearance",
    content: (
      <>
        <p>
          We are known for our high-service oriented offerings in the arena of
          bullion transportation, vaulting and clearance. It comprises of pick
          up, transportation, delivery and clearance with customized supply
          chain mechanisms. Our offerings have been leveraged by multiple
          industry segments like banking, finance, entertainment, logistics,
          transportation etc.
        </p>
      </>
    ),
  },
  "Advanced Currency Handling": {
    title: "Advanced Currency Handling",
    content: (
      <>
        <p>
          We indulge in advanced currency handling with proper management of
          currency exchanges in executing transaction in different currencies.
          We empower users to match currency conversion rates to different date
          segments and keep a track of the fluctuations in rate. We also help
          users map the currency rates to their ongoing opportunities.
        </p>
      </>
    ),
  },
  "Precision Cash Reconciliation Stations": {
    title: "Precision Cash Reconciliation Stations",
    content: (
      <>
        <p>
          We understand the importance of cash reconciliation and how accurate
          it must be. We have well-defined stations that can assist in precise
          cash reconciliation. Our taskforce is apt at reconciliation of inbound
          and outbound payment types and methods. Though this process is time
          consuming, there is a lot of relief that our services offer.
        </p>
      </>
    ),
  },
  "Elite Treasury Management": {
    title: "Elite Treasury Management",
    content: (
      <>
        <p>
          We serve one and all. We understand the complexities and care that is
          attached to the elite treasury. Our skilled taskforce takes into
          consideration the entire strategic movement of any organization’s
          financial asset and handle activities like risk assessment, cash
          management, ensuring industry standards etc.
        </p>
      </>
    ),
  },

  "High-Security Cash Fortification": {
    title: "High-Security Cash Fortification",
    content: (
      <>
        <p>
          Our high security cash van services include cutting-edge features such
          as high-resolution camera for the entire cargo, continual monitoring
          of vehicles, types of alerts and notifications, alarm switches for any
          kind of emergency alert and more. Our well-trained guards consider
          keeping the cash safe as their foremost priority and leave no stone
          unturned in doing so.
        </p>
      </>
    ),
  },
  "Concierge Banking Services": {
    title: "Concierge Banking Services",
    content: (
      <>
        <p>
          Our concierge banking services provide a dedicated banker to assist in
          achieving financial objective and fulfilling requirements. Such
          banking services incorporate different activities like availability of
          financial experts, planning for finances and investments, arranging
          different modes of payments and keeping all transactions private and
          secure.
        </p>
      </>
    ),
  },
};

const CaseMgmtOurServiceSection = () => {
  const [selectedService, setSelectedService] = useState(
    "Cash Transit & Logistics"
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const menuRef = useRef(null);
  const serviceRefs = useRef({});

  const handleMenuClick = (service) => {
    setSelectedService(service);
    setDropdownOpen(false);
    setShowContent(false);
    setTimeout(() => setShowContent(true), 5000);

    const rightSideService = document.querySelector(
      ".case_mgmt_our_service_right_side_services"
    );

    if (rightSideService) {
      const topPosition =
        rightSideService.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerHeight / 2 - rightSideService.offsetHeight / 2;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollMenuUp = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex > 0) {
      const prevService = Object.keys(services)[currentIndex - 1];
      setSelectedService(prevService);
      serviceRefs.current[prevService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollMenuDown = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex < Object.keys(services).length - 1) {
      const nextService = Object.keys(services)[currentIndex + 1];
      setSelectedService(nextService);
      serviceRefs.current[nextService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div className="case_mgmt_our_service_main_container">
      <div className="case_mgmt_our_service_title">
        <p>Our</p>
        <h3>Cash Management Services</h3>
      </div>
      <div className="case_mgmt_our_service_menu_bar">
        <div className="case_mgmt_our_service_left_side_menu">
          <div className="dropdown_menu_container">
            <div
              className="case_mgmt_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <button
              className="dropdown_button"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {services[selectedService].title}
            </button>

            <div
              className="case_mgmt_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div>

          <div className="case_mgmt_our_service_grid_container">
            {Object.keys(services).map((service) => (
              <div
                key={service}
                className={`service_item ${
                  service === selectedService ? "selected" : ""
                }`}
                onClick={() => handleMenuClick(service)}
                ref={(el) => (serviceRefs.current[service] = el)}
              >
                {/* <span
                  className={`service_dot ${
                    service === selectedService ? "dot-active" : ""
                  }`}
                ></span> */}
                {/* <div className="service_item_content">{service}</div> */}
                <div className="case_mgmt_our_service_item_content">
                  <span className="case_mgmt_our_service_item_dot">
                    {service === selectedService && (
                      <img src={home_chess} alt="home chess icon" />
                    )}
                  </span>
                  {service}
                </div>
              </div>
            ))}
          </div>

          {/* ICON  */}

          {/* <div className="out_service_button_icon">
            <div
              className="case_mgmt_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <div
              className="case_mgmt_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div> */}
        </div>

        <div className="case_mgmt_our_service_right_side_services">
          <div className="case_mgmt_our_service_right_side_services_title">
            <p>{services[selectedService].title}</p>
            <div className="case_mgmt_our_service_horizontal_line"></div>
          </div>
          <div
            className={`case_mgmt_our_service_right_side_services_content ${
              showContent ? "fade-in" : "fade-out"
            }`}
          >
            {services[selectedService].content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseMgmtOurServiceSection;
