import React, { useState, useRef } from "react";
import "./FireSafetyOurServiceSection.css";
import home_chess from "../../../../assets/icons/home_chess.svg";

import up_icon from "../../../../assets/icons/our_service_section_left_side_up_icon.svg";
import down_icon from "../../../../assets/icons/our_service_section_left_side_down_icon.svg";

const services = {
  "Fire Prevention": {
    title: "Fire Prevention",
    content: (
      <>
        <p>
          Our well-skilled and vigilant fire prevention taskforce are trained
          efficiently in offering different types of services like supressing
          and detecting fire, monitoring and inspecting locations, maintenance
          of fire equipment, installation of sprinkler systems etc. We ensure
          complete compliance with industry standards and abide by the
          government norms to ensure a pitch perfect service offering. Our fire
          prevention training courses embed the knowledge and skills involved at
          various levels of the fire prevention arena.
        </p>
      </>
    ),
  },
  "Crisis Management": {
    title: "Crisis Management",
    content: (
      <>
        <p>
          We understand the need of the hour and offer skilled training services
          to our taskforce that not only trains them to handle crisis and
          emergencies with their technical finesse but also maintain their
          mental equilibrium in such a way that helps them to be their
          proactive, intelligent and practical best. Our advanced Emergency
          Response training facility near Vadodara has been collaborating with
          Rashtriya Raksha University to offer residential fire-fighting
          training programs with sponsorship assistance to eligible candidates.
        </p>
      </>
    ),
  },
  "Hazardous Materials Response": {
    title: "Hazardous Materials Response",
    content: (
      <>
        <p>
          Our detailed training programs are exhaustive and include classroom
          instructions with realistic hands-on simulations for hazardous
          material disasters. Our fighters are well trained to respond promptly
          and accurately while there is a release of any type of
          substance/product that is harmful, which could be released by nature
          or human. Our training services have been accredited as a JOIFF
          (International Organization for Industrial Hazard Management) training
          establishment.
        </p>
      </>
    ),
  },
  "Customized Training": {
    title: "Customized Training",
    content: (
      <>
        <p>
          Our skilled experts realize that ‘one size does not fit all’ and each
          client has their own set of requirements that need tailoring and hence
          we offer customized training services that look at your own need. We
          use real-life simulators and props that make use of different
          materials like real fuel and extinguishing agents, and comprises
          full-scale replicas of industrial equipment & structures, such as
          refinery processing units, etc to provide realistic, applied training.
        </p>
      </>
    ),
  },
  "Disaster Management": {
    title: "Disaster Management",
    content: (
      <>
        <p>
          Disaster could be anywhere and everywhere and so are our trained
          teams. We encompass any and every nook and corner to ensure that we
          can protect you from any kind of disaster. We cover industry segments
          like oil & gas, petrochemical, chemical, power, automobiles,
          fertilizers, transportation and hospitality. We follow a systematic
          approach that showcases the immense experience and expertise that our
          team has, in terms of managing, foreseeing and avoiding disasters.
        </p>
      </>
    ),
  },
  "Fire Risk Mitigation": {
    title: "Fire Risk Mitigation",
    content: (
      <>
        <p>
          Our fire risk mitigation strategies include conducting routing
          inspections, keeping the taskforce trained, planning for any type of
          emergency and much more. We believe in early planning of activities to
          ensure that whenever any fire emergency arises, we are well prepared
          ahead of time. We implement certain activities in a pre-planned manner
          so that we can play safe from fire.
        </p>
      </>
    ),
  },
  "Combustion Safeguarding": {
    title: "Combustion Safeguarding",
    content: (
      <>
        <p>
          We implement modern-day combustion safeguarding systems that act when
          there is a flame sensed and switch off the fuel if the flame does not
          work. We implement different kinds of systems that can test combustion
          standards for different equipment like dryers, boilers etc. There are
          flame sensing systems that can sense the existence of flame.
        </p>
      </>
    ),
  },
  "Tactical Retrieval": {
    title: "Tactical Retrieval",
    content: (
      <>
        <p>
          This service offering is more towards those who are looking forward to
          fighting their mental illness and any kind of substance use issues. We
          help such people overcome their issues and get involved in the
          treatment, education, therapy, exercises, detoxification etc.
        </p>
      </>
    ),
  },
  "Tailored Safety Training": {
    title: "Tailored Safety Training",
    content: (
      <>
        <p>
          Our fire prevention training courses embed the knowledge and skills
          involved at various levels of the fire prevention arena. Our advanced
          Emergency Response training facility near Vadodara has been
          collaborating with Rashtriya Raksha University to offer residential
          fire-fighting training programs with sponsorship assistance to
          eligible candidates. Our training services have been accredited as a
          JOIFF (International Organization for Industrial Hazard Management)
          training establishment.
        </p>
      </>
    ),
  },

  "Exodus Strategy": {
    title: "Exodus Strategy",
    content: (
      <>
        <p>
          Our powerful training services offer skilled rescue operators as an
          output who can manage and handle all types of rescue operations, to
          bring people out of danger, attack, harm etc. Be it natural calamity
          or man-made disaster, our brave and proficient taskforce is so
          well-trained and balanced in their thoughts, that they can manage any
          situation with perfection.
        </p>
      </>
    ),
  },
  "Emergency Egress Solutions": {
    title: "Emergency Egress Solutions",
    content: (
      <>
        <p>
          In an emergency, our emergency egress solutions help implement certain
          functions so that people escape unhurt and there is no mishap. This
          could include triggering of a location’s fire alarm system that can
          unlock doors, opening of emergency doors, activating alarms etc.
        </p>
      </>
    ),
  },
  "Operational Safety Framework": {
    title: "Operational Safety Framework",
    content: (
      <>
        <p>
          We implement an operational safety framework that can manage, identify
          and handle any kind of hazard that could lead of mishaps. It also
          includes training protocols to our taskforce to attend to any kind of
          safety requirements. We periodically assess the happenings, monitor
          the equipment and their performance.
        </p>
      </>
    ),
  },
  "Industrial Safety Optimization": {
    title: "Industrial Safety Optimization",
    content: (
      <>
        <p>
          Of all locations, when it comes to fire, the industry segment is a
          vital area to be careful about. Since it is all about machines and
          equipment, chances of fire and its heavy impact on human life can be
          hazardous. We optimize all our processes that aim towards complete
          industrial safety and mitigation so that no unforeseen event occurs.
        </p>
      </>
    ),
  },
};

const FireSafetyOurServiceSection = () => {
  const [selectedService, setSelectedService] = useState("Fire Prevention");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const menuRef = useRef(null);
  const serviceRefs = useRef({});

  const handleMenuClick = (service) => {
    setSelectedService(service);
    setDropdownOpen(false);
    setShowContent(false);
    setTimeout(() => setShowContent(true), 5000);

    const rightSideService = document.querySelector(
      ".fire_safety_our_service_right_side_services"
    );

    if (rightSideService) {
      const topPosition =
        rightSideService.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerHeight / 2 - rightSideService.offsetHeight / 2;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollMenuUp = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex > 0) {
      const prevService = Object.keys(services)[currentIndex - 1];
      setSelectedService(prevService);
      serviceRefs.current[prevService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollMenuDown = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex < Object.keys(services).length - 1) {
      const nextService = Object.keys(services)[currentIndex + 1];
      setSelectedService(nextService);
      serviceRefs.current[nextService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  // fire_safety_our_service_main_container
  return (
    <div className="fire_safety_our_service_main_container">
      <div className="fire_safety_our_service_title">
        <p>Our</p>
        <h3>Fire & Safety Services</h3>
      </div>
      <div className="fire_safety_our_service_menu_bar">
        <div className="fire_safety_our_service_left_side_menu">
          <div className="dropdown_menu_container">
            <div
              className="fire_safety_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <button
              className="dropdown_button"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {services[selectedService].title}
            </button>

            <div
              className="fire_safety_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div>

          <div className="fire_safety_our_service_grid_container">
            {Object.keys(services).map((service) => (
              <div
                key={service}
                className={`service_item ${
                  service === selectedService ? "selected" : ""
                }`}
                onClick={() => handleMenuClick(service)}
                ref={(el) => (serviceRefs.current[service] = el)}
              >
                {/* <span
                  className={`service_dot ${
                    service === selectedService ? "dot-active" : ""
                  }`}
                ></span> */}
                {/* <div className="service_item_content">{service}</div> */}
                <div className="fire_safety_our_service_item_content">
                  <span className="fire_safety_our_service_item_dot">
                    {service === selectedService && (
                      <img src={home_chess} alt="home chess icon" />
                    )}
                  </span>
                  {service}
                </div>
              </div>
            ))}
          </div>

          {/* ICON  */}

          {/* <div className="out_service_button_icon">
            <div
              className="fire_safety_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <div
              className="fire_safety_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div> */}
        </div>

        <div className="fire_safety_our_service_right_side_services">
          <div className="fire_safety_our_service_right_side_services_title">
            <p>{services[selectedService].title}</p>
            <div className="fire_safety_our_service_horizontal_line"></div>
          </div>
          <div
            className={`fire_safety_our_service_right_side_services_content ${
              showContent ? "fade-in" : "fade-out"
            }`}
          >
            {services[selectedService].content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FireSafetyOurServiceSection;
