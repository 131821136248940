import React, { useState } from "react";
import "./VideoSection.css";
import "../../../assets/color/root_color.css";
import play_btn from "../../../assets/icons/video_section_play_btn.svg";
import DialogBox from "./DialogBox";
import { Link } from "react-router-dom";

const VideoSection = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  // const openDialog = () => setDialogOpen(true);
  const openDialog = () => {
    const rightSide = document.querySelector(".video_section_right_side");
    const rightSide82 = document.querySelector(".video_section_right_side_82");
    const rightSideBgColor = document.querySelector(
      ".video_section_right_side_bg_color"
    );

    // Remove previous animation classes if any
    rightSide.classList.remove("animateColors", "complete");
    rightSide82.classList.remove("animateColors", "complete");
    rightSideBgColor.classList.remove("animateColors", "complete");

    // Add animation class
    rightSide.classList.add("animateColors");
    rightSide82.classList.add("animateColors");
    rightSideBgColor.classList.add("animateColors");

    setTimeout(() => {
      rightSide.classList.add("complete");
      rightSide82.classList.add("complete");
      rightSideBgColor.classList.add("complete");
    }, 1000);

    // Open dialog after animation starts
    setTimeout(() => {
      setDialogOpen(true);
    }, 1000);
  };
  const closeDialog = () => setDialogOpen(false);
  return (
    <>
      <div className="video_section_main_container">
        <div className="video_section_left_side">
          <div className="video_section_left_side_content">
            <h6>LOOK NO FURTHER</h6>
            <h3>Are You Seeking Security or Facility Services?</h3>
            <p>
              You are at the right place. Reach out to us and enjoy a wholesome
              experience, leaving all the burden on our shoulders.
            </p>
            <Link to="/contact">
              <button type="button">Contact Us</button>
            </Link>
          </div>
        </div>

        <div className="video_section_right_side">
          <div className="video_section_right_side_82">
            <div className="video_section_right_side_bg_color">
              <button onClick={openDialog} className="play-btn">
                <img src={play_btn} alt="Play Button" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && <DialogBox onClose={closeDialog} />}
    </>
  );
};

export default VideoSection;
