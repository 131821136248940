import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Service from "../pages/Service/Service";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import SecurityServices from "../pages/Service/SecurityServices/SecurityServices";
import FireSafetyServices from "../pages/Service/FireSafetyServices/FireSafetyServices";
import ContactUs from "../pages/ContactUs/ContactUs";
import Industries from "../pages/Industries/Industries";
import Banner from "../pages/About/Banner/Banner";
import OurTeam from "../pages/About/OurTeam/OurTeam";
import FacilityMgmtServices from "../pages/Service/FacilityMgmtServices/FacilityMgmtServices";
import ElectronicSecurityServices from "../pages/Service/ElectronicSecurityServices/ElectronicSecurityServices";
import CaseMgmtServices from "../pages/Service/CaseMgmtServices/CaseMgmtServices";
import EventMgmtServices from "../pages/Service/EventMgmtServices/EventMgmtServices";

const RouteComponent = () => {
  const WebsitePage = ({ component: Component }) => {
    return (
      <>
        <Header />
        <Component />
        <Footer />
      </>
    );
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<WebsitePage component={Home} />} />
        <Route path="/about" element={<WebsitePage component={About} />}>
          <Route path="banner" element={<WebsitePage component={Banner} />} />
          <Route path="ourteam" element={<WebsitePage component={OurTeam} />} />
        </Route>

        <Route path="/services" element={<WebsitePage component={Service} />} />

        <Route
          path="/services/security-service"
          element={<WebsitePage component={SecurityServices} />}
        />
        <Route
          path="/services/fire-safety-service"
          element={<WebsitePage component={FireSafetyServices} />}
        />
        <Route
          path="/services/facility-management-service"
          element={<WebsitePage component={FacilityMgmtServices} />}
        />

        <Route
          path="/services/electronic-security-service"
          element={<WebsitePage component={ElectronicSecurityServices} />}
        />
        <Route
          path="/services/cash-management-service"
          element={<WebsitePage component={CaseMgmtServices} />}
        />
        <Route
          path="/services/event-management-service"
          element={<WebsitePage component={EventMgmtServices} />}
        />
        <Route
          path="/contact"
          element={<WebsitePage component={ContactUs} />}
        />
        <Route
          path="/industries"
          element={<WebsitePage component={Industries} />}
        />
      </Routes>
    </>
  );
};

export default RouteComponent;
