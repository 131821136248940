import React from "react";
import "./FireSafetyServiceOverviewSection.css";

const FireSafetyServiceOverviewSection = () => {
  return (
    <>
      <div className="fire_safety_service_overview_main_container">
        <div className="fire_safety_service_overview_title">
          <p>OverView</p>
          <h3>Fire & Safety Services</h3>
        </div>
        <div className="fire_safety_service_overview_content">
          <p>
            Perceiving the key importance of training in fire and safety related
            activities, our specialized service offering for fire and safety
            training comprises of a wide-ranging set of activities that includes
            an international standard training and consultancy for fire, safety,
            rescue, disaster management, crisis and emergency operations.
            Powered by the Checkmate group, Institute of Fire Safety and
            Disaster Management Studies (IFSDMS) was founded in 1998 and
            registered as a private trust under the Registrar of Societies in
            2007. We provide certified, intense and realistic training,
            globally, approved by the Government of Gujarat, for fireman,
            response professionals and sub fire officers.
            <br />
            <br />
            Over these years, we have been serving the emergency response and
            training needs of companies be it private sector or government
            agencies, through our comprehensive and customized training modules.
            In 2012, Hon. Shri Narendra Modi inaugurated a new zero discharge
            fire and safety training facility that is spread over 100 acres of
            land. It contains full-scape replicas of industrial mock-ups with
            live fire burning facilities and an oil/water separation plant with
            capacity of 1000m3. It was created with technical collaboration and
            expert guidance from erstwhile world-famous Fire Science Academy
            Worldwide, University of Nevada, USA.
          </p>
        </div>
      </div>
    </>
  );
};

export default FireSafetyServiceOverviewSection;
