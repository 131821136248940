import React from "react";
import "./IndustriesOverviewSection.css";

const IndustriesOverviewSection = () => {
  return (
    <>
    
      <div className="industries_overview_main_container">
        <div className="industries_overview_title">
          <p>OverView</p>
          <h3>Industries</h3>
        </div>
        <div className="industries_overview_content">
          <p>
            Lorem ipsum dolor sit amet consectetur, Nec viverra diam et cras
            lorem.Lorem ipsum dolor sit amet consectetur, Nec viverra diam et
            cras lorem.Lorem ipsum dolor sit amet consectetur, Nec viverra diam
            et cras lorem.Lorem ipsum dolor sit amet consectetur, Nec viverra
            diam et cras lorem.Lorem ipsum dolor sit amet consectetur, Nec
            viverra diam et cras lorem.Lorem ipsum dolor sit amet consectetur,
            Nec viverra diam et cras lorem.Lorem ipsum dolor sit amet
            consectetur, Nec viverra diam et cras lorem.Lorem ipsum dolor sit
            amet consectetur, Nec viverra diam et cras lorem.Lorem ipsum dolor
            sit amet consectetur, Nec viverra diam et cras lorem.
            <br />
            <br />
            Lorem ipsum dolor sit amet consectetur, Nec viverra diam et cras
            lorem.Lorem ipsum dolor sit amet consectetur, Nec viverra diam et
            cras lorem.Lorem ipsum dolor sit amet consectetur, Nec viverra diam
            et cras lorem.Lorem ipsum dolor sit amet consectetur, Nec viverra
            diam et cras lorem.
          </p>
        </div>
      </div>
    </>
  );
};

export default IndustriesOverviewSection;
