import React from "react";
import "./OurTeam.css";
// import team2 from "../../../assets/images/about_section_our_team_2.png";
import samarjeet from "../../../assets/images/about_section_samarjeet_mahurkar.svg";
import sujeet from "../../../assets/images/about_section_sujeet_singh_rawal.png";
import prithavi from "../../../assets/images/about_section_prithvi_singh_jagtap.png";

const OurTeam = () => {
  const teamMembers = [
    {
      name: "Samarjeet Mahurkar",
      title: "Director & CEO - CFESPL",
      image: samarjeet,
      description:
        "An esteemed figure in the corporate landscape, he has solidified his reputation as a visionary executive with profound insights into the security industry. He orchestrates strategic initiatives that propel the company to the forefront. A young visionary with team leadership qualities, dedicated to advancing security solutions, that prioritize innovation and safety.",
    },
    {
      name: "Prithvi Singh Jagtap",
      title: "CEO - CSPL",
      image: prithavi,
      description:
        "Over 30 years of experience in the security services industry, specializing in operational leadership and strategic growth. Instrumental in expanding company operations into newer markets and avenues through implementation of innovative security technologies. Stringent focus on client satisfaction and high-end quality.",
    },
    {
      name: "Capt. Sujeet Singh Rawal",
      title: "CEO - BANKING",
      image: sujeet,
      description:
        "A distinguished professional with a wealth of expertise spanning various domains, prominently in corporate and retail security, logistics, and banking services. He plays a pivotal role in overseeing all aspects of cash flow operations and management. Expert in strategic planning and execution, clubbed with risk management and compliance.",
    },
  ];

  return (
    <div className="team-section">
      <p className="team-paragraph">Our Expert</p>
      <h3 className="team-heading">Management Team</h3>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div className="team-card" key={index}>
            <div className="team-image-container">
              <img
                src={member.image}
                alt={member.name}
                className="team-image"
              />
              <div className="team-overlay">
                <p>{member.description}</p>
              </div>
            </div>
            <div className="team-info">
              <h3>{member.name}</h3>
              <p>{member.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
