import React, { useState } from "react";
import "./ContactTalkUs.css";
import contactus_touch_mail_icon from "../../../assets/icons/contactus_touch_mail_icon.svg";
import contactus_touch_location_icon from "../../../assets/icons/contactus_touch_location_icon.svg";
import contactus_touch_call_icon from "../../../assets/icons/contactus_touch_call_icon.svg";

const ContactTalkUs = () => {
  const [activeSection, setActiveSection] = useState(false);

  const handleNext = () => {
    setActiveSection((prevSection) =>
      prevSection === 2 ? 0 : prevSection + 1
    );
  };

  const handlePrev = () => {
    setActiveSection((prevSection) =>
      prevSection === 0 ? 2 : prevSection - 1
    );
  };

  // Handler for direct div clicks
  const handleSectionClick = (index) => {
    setActiveSection(index);

    if (index === 0) {
      window.open("mailto:contact@checkmateservices.com", "_blank");
    } else if (index === 2) {
      window.open(
        "https://wa.me/9192656698200?text=Hello%20Checkmate%20Services!",
        "_blank"
      );
    }
  };

  return (
    <div className="contactus_talk_main_container">
      <div className="contactus_talk_title">
        <p>Let’s Talk</p>
        <h3>Get In Touch with us</h3>
      </div>

      <div className="contactus_talk_content">
        {/* Mail Us */}
        <div
          className={`contactus_talk_content_1 ${
            activeSection === 0 ? "active" : ""
          }`}
          onClick={() => handleSectionClick(0)}
        >
          <div className="contactus_talk_content_1_middle">
            <div className="contactus_talk_content_1_image">
              <img src={contactus_touch_mail_icon} alt="Mail Icon" />
            </div>
            <div className="contactus_talk_content_1_title">
              <h6>Mail Us</h6>
              <p>contact@checkmateservices.com</p>
            </div>
          </div>
        </div>

        {/* Head Office */}
        <div
          className={`contactus_talk_content_2 ${
            activeSection === 1 ? "active" : ""
          }`}
          onClick={() => handleSectionClick(1)}
        >
          <div className="contactus_talk_content_2_middle">
            <div className="contactus_talk_content_2_image">
              <img src={contactus_touch_location_icon} alt="Location Icon" />
            </div>
            <div className="contactus_talk_content_2_title">
              <h6>Checkmate Head Office</h6>
              <p>
                {" "}
                {/* Checkmate Corporate Office Amaan Towers,
                <br />
                GF - 6,7,8,9, Suvas Colony, Fatehgunj,
                <br />
                Vadodara. Gujarat (India).  */}
                <br />
                1111-1122, 11th Floor, Block - 1B,
                <br />
                73 East Avenue Bhailal Amin Marg,
                <br />
                Sarabhai Campus Vadodara 390023
                <br />
                Gujarat (India).
              </p>
            </div>
          </div>
        </div>

        {/* Call Us */}
        <div
          className={`contactus_talk_content_3 ${
            activeSection === 2 ? "active" : ""
          }`}
          onClick={() => handleSectionClick(2)}
        >
          <div className="contactus_talk_content_3_middle">
            <div className="contactus_talk_content_3_image">
              <img src={contactus_touch_call_icon} alt="Call Icon" />
            </div>
            <div className="contactus_talk_content_3_title">
              <h6>Call Us</h6>
              <p>+91 265 6698200</p>
            </div>
          </div>
        </div>
      </div>

      {/* Map Section  */}
      <div className="contactus_talk_map_section">
        <div className="map_section_right">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.8936254162336!2d73.16642317434524!3d22.319862442187926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc9d490c985c5%3A0xd6f67a22cce6d35e!2s73%20east%20avenue!5e0!3m2!1sen!2sin!4v1727673776298!5m2!1sen!2sin"
            // width={1120}
            // height={475}
            // style={{ border: "0px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactTalkUs;
