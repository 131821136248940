import React from "react";
import Home from "./pages/Home/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./pages/About/About";
import Service from "./pages/Service/Service";
import RouteComponent from "./routing/RouteComponent";

export default function App() {
  return (
    <>
      <RouteComponent />
    </>
  );
}
