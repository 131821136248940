import React, { useState } from "react";
import "./IndustriesWeServerSection.css";
import right_side_arrow from "../../../assets/icons/industry_right_side_arrow.svg";
import serve_we_banking from "../../..//assets/icons/industry_serve_we_banking.svg";

import security_service_icon from "../../../assets/icons/service_section_popup_security_service.svg";
import facility_meng_icon from "../../../assets/icons/service_section_popup_facility_man.svg";
import fire_safety_iocn from "../../../assets/icons/service_section_popup_firesafety.svg";
import electronic_security_icon from "../../../assets/icons/service_section_popup_electronic_security.svg";
import cash_meng from "../../../assets/icons/service_section_popup_cash_man.svg";
import event_meng from "../../../assets/icons/service_section_popup_event_man.svg";

import industry_bank from "../../../assets/icons/industry_bank.svg";
import industry_Corporate from "../../../assets/icons/industry_Corporate.svg";
import industry_Education from "../../../assets/icons/industry_Education.svg";
import industry_FMCG from "../../../assets/icons/industry_FMCG.svg";
import industry_Hospitality from "../../../assets/icons/industry_Hospitality.svg";
import industry_Information_Technology from "../../../assets/icons/industry_Information_Technology.svg";
import industry_Manufacturing from "../../../assets/icons/industry_Manufacturing.svg";
import industry_Mining from "../../../assets/icons/industry_Mining.svg";
import industry_Petro_Chemical from "../../../assets/icons/industry_Petro_Chemical.svg";
import industry_Ports from "../../../assets/icons/industry_Ports.svg";

const serviceImages = {
  "Security Services": security_service_icon,
  "Cash Management": cash_meng,
  "Facility Management": facility_meng_icon,
  "Fire & Safety": fire_safety_iocn,
  "Electronic Security": electronic_security_icon,
  "Event Management": event_meng,
};

const industryImages = {
  Corporate: industry_Corporate,
  "Banking / BFSI": industry_bank,
  "Information Technology": industry_Information_Technology,
  Manufacturing: industry_Manufacturing,
  Education: industry_Education,
  Hospitality: industry_Hospitality,
  Ports: industry_Ports,
  Mining: industry_Mining,
  "Retail & FMCG": industry_FMCG,
  "Chemical / Petro Chemical": industry_Petro_Chemical,
};

const industriesData = [
  {
    name: "Corporate",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Facility Management", "Fire & Safety","Electronic Security","Event Management"],
  },
  {
    name: "Banking / BFSI",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Facility Management", "Fire & Safety","Electronic Security","Cash Management","Event Management"],
  },
  {
    name: "Information Technology",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Facility Management", "Fire & Safety","Electronic Security","Event Management"],
  },
  {
    name: "Manufacturing",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Facility Management", "Fire & Safety","Electronic Security","Event Management"],
  },
  {
    name: "Education",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Facility Management","Electronic Security","Event Management"],
  },
  {
    name: "Hospitality",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Facility Management", "Electronic Security","Event Management"],
  },
  {
    name: "Ports",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Facility Management", "Fire & Safety","Electronic Security","Event Management"],
  },
  {
    name: "Mining",
    description:
      "We offer a comprehensive suite of digital services tailored to meet the diverse needs of our clients. ",
    services: ["Security Services","Facility Management", "Fire & Safety","Electronic Security","Event Management"],
  },
  {
    name: "Retail & FMCG",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Facility Management", "Electronic Security","Cash Management","Event Management"],
  },
  {
    name: "Chemical / Petro Chemical",
    description:
      "Lörem ipsum egongar zombiebrand blåkort kagäde. Pinterest nylig, dåbes. Polugt neostat om än dire. ",
    services: ["Security Services","Fire & Safety","Electronic Security","Cash Management","Event Management"],
  },
];
const IndustriesWeServerSection = () => {
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleIndustryClick = (industry) => {
    setSelectedIndustry(industry);
  };

  const handleClosePopup = () => {
    setSelectedIndustry(null);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="industries_we_server_main_container">
        <div className="industries_we_server_title">
          <p>Industries We Serve</p>
        </div>

        <div className="industries_we_server_content">
          <ul className="industries-list">
            {industriesData.map((industry, index) => (
              <li
                key={index}
                className="industry_item"
                onClick={() => handleIndustryClick(industry)}
              >
                <div className="industry_bg">
                  {/* Background image is applied here */}
                  <div
                    className="industry_bg_image"
                    style={{
                      backgroundImage: `url(${industryImages[industry.name]})`,
                    }}
                  ></div>
                </div>
                <div className="industry_details">
                  <div className="industry_name">
                    <h4>{industry.name}</h4>
                    <p>{industry.description}</p>
                  </div>
                  <div
                    className={`industry_arrow_icon ${
                      selectedIndustry?.name === industry.name ? "rotated" : ""
                    }`}
                  >
                    <img src={right_side_arrow} alt="arrow icon" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {selectedIndustry && (
          <div className="industries_popup">
            <div className="industries_popup_content">
              <h3>{selectedIndustry.name}</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur, Nec viverra diam et cras
                lorem. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Tempore sit in ducimus omnis repudiandae suscipit dolorum,
                quibusdam accusantium voluptatibus. Impedit laboriosam ducimus
                vero exercitationem, atque fugiat nam eligendi a
                provident.______
                <br />
                {isExpanded && (
                  <>
                    |||||Lorem ipsum dolor sit amet consectetur adipisicing
                    elit. Mollitia, earum. Lorem ipsum, dolor sit amet
                    consectetur adipisicing elit. Obcaecati eveniet consequuntur
                    incidunt fugit beatae optio qui quasi iste, ex assumenda
                    nemo suscipit nesciunt. Provident sequi saepe maxime quidem,
                    ad minima deserunt aperiam laudantium enim! Voluptates
                    accusantium, tempora, dolor voluptas obcaecati
                    necessitatibus eius assumenda, quod magni delectus fuga
                    porro minus repellendus.
                  </>
                )}
                <button className="read_more_button" onClick={toggleExpand}>
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              </p>
              {selectedIndustry.services && (
                <div className="industries_services_served">
                  <h4>Services Served</h4>
                  <div className="industries_services_served_list">
                    {selectedIndustry.services.map((service, index) => (
                      <div className="service_item" key={index}>
                        <img src={serviceImages[service]} alt={service} />
                        <p>{service}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <button
                className="industries_close_popup"
                onClick={handleClosePopup}
              >
                ✕
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default IndustriesWeServerSection;
