import React from "react";
import "./CaseMgmtServiceOverviewSection.css";

const CaseMgmtServiceOverviewSection = () => {
  return (
    <>
      <div className="case_mgmt_overview_main_container">
        <div className="case_mgmt_overview_title">
          <p>OverView</p>
          <h3>Cash Management Services</h3>
        </div>
        <div className="case_mgmt_overview_content">
          <p>
            We @Checkmate understand precisely how cash is considered the
            oxygen of your business. As per the Reserve Bank of India directive
            on cash handling, it specifies that banks can outsource different
            cash related activities like cash in transit, doorstep banking, cash
            vaulting etc. Considering cash as of the most precious assets of any
            business, our dedicated taskforce delivers timely and secure cash
            and valuable handling services across the country. Geography no
            bound, our cash management services have been spread across remote
            areas around. We have dedicated 500+ fleet of cash vans to banks,
            with surveillance systems, 4000 trained and armed guards, a driver
            and a cash loader for daily cash transfer. Our support extends pan
            India through their currency processing chests.
            <br />
            <br />
            Our currency management services include picking up cash from
            branches-both intra and inter-city–and from the central bank. We
            implement finest of surveillance systems while the transfer process
            is on for movement of notes and coins, to different destinations. We
            undertake industry-standard measures that are responsive and
            strategic. Our well-trained personnel unleash the potential of the
            used technology in the best possible manner. We are competent for
            different degrees of sorting like ATM fit, over-the-counter
            issuable, we select the best combination of team members, vehicles,
            route management, reporting strategies and tracking mechanisms. A
            detailed risk assessment and mitigation is done for each cash
            management activity happening, with a thorough security drill.
          </p>
        </div>
      </div>
    </>
  );
};

export default CaseMgmtServiceOverviewSection;
