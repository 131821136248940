import React, { useState } from "react";
import "../../assets/color/root_color.css";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";

import footer_section_location_white from "../../assets/icons/footer_section_location_white.svg";
import footer_section_location_green from "../../assets/icons/footer_section_location.svg";
import footer_section_calling_white from "../../assets/icons/footer_section_calling.svg";
import footer_section_calling_green from "../../assets/icons/footer_section_calling_green.svg";

import ul_li_white from "../../assets/icons/footer_section_ul_li_white_arrow.svg";
import footer_logo from "../../assets/images/footer_logo.png";

import facebook_bg_black_icon from "../../assets/icons/footer_section_facebook_bg_black.svg";
import instagram_bg_black_icon from "../../assets/icons/footer_section_instagram_bg_black.svg";
import youtube_bg_black_icon from "../../assets/icons/footer_section_youtube_bg_black.svg";
import twitter_bg_black_icon from "../../assets/icons/fooer_section_twitter_bg_black.svg";
import linkedln_bg_black_icon from "../../assets/icons/fooer_section_linkedln_bg_black.svg";

const Footer = () => {
  const location = useLocation();
  const [activeIcon, setActiveIcon] = useState(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const openWhatsApp = (number) => {
    const url = `https://wa.me/${number}`;
    openInNewTab(url);
  };

  const handleIconClick = (url, iconName) => {
    window.open(url, "_blank");
    setActiveIcon(iconName);
  };
  const openGoogleMaps = () => {
    const officeAddress =
      "1111 - 1122 , 11th Floor , Block - 1B , 73 East Avenue ,  Bhailal Amin Marg  , Sarabhai Campus , Vadodara - 390023, Gujarat (India).";
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      officeAddress
    )}`;
    window.open(mapsUrl, "_blank");
  };
  const handleMouseEnter = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const footerSections = [
    {
      header: "Our Head Office",
      items: [
        {
          icon:
            hoveredIcon === "location"
              ? footer_section_location_green
              : footer_section_location_white,
          // text: "Checkmate Corporate Office Amaan Towers, GF – 6,7,8,9, Suvas Colony, Fatehgunj, Vadodara. Gujarat (India).",
          // text: "Office No. 1111- 1122, 11th Floor , Block - 1B , 73 East Avenue ,  Bhailal Amin Marg , Sarabhai Campus , Vadodara - 390023 , Gujarat (India).",
          text: "1111 - 1122, 11th Floor,  Block - 1B,73 East Avenue, Bhailal Amin Marg, Sarabhai Campus, Vadodara - 390023, Gujarat (India).",
          className: "footer_section1_content_location",
          onClick: openGoogleMaps,
          onMouseEnter: () => handleMouseEnter("location"),
          onMouseLeave: handleMouseLeave,
        },
        {
          icon:
            hoveredIcon === "calling"
              ? footer_section_calling_green
              : footer_section_calling_white,
          text: "+91 265 6698200",
          className: "footer_section1_content_calling",
          onClick: () => openWhatsApp("912656698200"),
          onMouseEnter: () => handleMouseEnter("calling"),
          onMouseLeave: handleMouseLeave,
        },
      ],
    },

    {
      header: "",
      items: [
        {
          text: "Home",
          className:
            location.pathname === "/"
              ? "footer-section_header_ul_li_main active"
              : "footer-section_header_ul_li_main",
          link: "/",
        },
        {
          text: "About Us",
          className:
            location.pathname === "/about"
              ? "footer-section_header_ul_li active"
              : "footer-section_header_ul_li",
          link: "/about",
        },
        {
          text: "Industries",
          className:
            location.pathname === "/industries"
              ? "footer-section_header_ul_li active"
              : "footer-section_header_ul_li",
          link: "/industries",
        },
        {
          text: "Contact Us",
          className:
            location.pathname === "/contact"
              ? "footer-section_header_ul_li active"
              : "footer-section_header_ul_li",
          link: "/contact",
        },
      ],
    },
    {
      header: "Our Services",
      items: [
        {
          icon: ul_li_white,
          icon1: ul_li_white,
          text: "Security Services",
          className:
            location.pathname === "/services/security-service"
              ? "footer-section_header_ul_li_main active"
              : "footer-section_header_ul_li_main",
          link: "/services/security-service",
        },
        {
          icon: ul_li_white,
          icon1: ul_li_white,
          text: "Facility Management Services",
          className:
            location.pathname === "/services/facility-management-service"
              ? "footer-section_header_ul_li_main active"
              : "footer-section_header_ul_li_main",
          link: "/services/facility-management-service",
        },

        {
          icon: ul_li_white,
          icon1: ul_li_white,
          text: "Fire & Safety Services",
          className:
            location.pathname === "/services/fire-safety-service"
              ? "footer-section_header_ul_li_main active"
              : "footer-section_header_ul_li_main",
          link: "/services/fire-safety-service",
        },
        {
          icon: ul_li_white,
          icon1: ul_li_white,
          text: "Electronic Security Services",
          className:
            location.pathname === "/services/electronic-security-service"
              ? "footer-section_header_ul_li_main active"
              : "footer-section_header_ul_li_main",
          link: "/services/electronic-security-service",
        },
        {
          icon: ul_li_white,
          icon1: ul_li_white,
          text: "Cash Management Services",
          className:
            location.pathname === "/services/cash-management-service"
              ? "footer-section_header_ul_li_main active"
              : "footer-section_header_ul_li_main",
          link: "/services/cash-management-service",
        },
        {
          icon: ul_li_white,
          icon1: ul_li_white,
          text: "Event Management Services",
          className:
            location.pathname === "/services/event-management-service"
              ? "footer-section_header_ul_li_main active"
              : "footer-section_header_ul_li_main",
          link: "/services/event-management-service",
        },
      ],
    },
  ];

  return (
    <>
      <footer className="footer_section_main_container">
        {footerSections.map((section, index) => (
          <div key={index} className="footer-section">
            <p className="footer-section_header">{section.header}</p>
            <ul>
              {section.items.map((item, i) => (
                <li
                  key={i}
                  onClick={
                    item.onClick ||
                    (item.link
                      ? () => (window.location.href = item.link)
                      : undefined)
                  }
                  className={item.className}
                  onMouseEnter={item.onMouseEnter}
                  onMouseLeave={item.onMouseLeave}
                >
                  {/* {item.icon && <img src={item.icon} alt="" />} */}
                  <img src={item.icon} alt="" />
                  <img src={item.icon1} alt="" />

                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div className="footer-section">
          <p className="footer-section_header">Connect with Us</p>
          <div className="footer-section_icons">
            {/* <div
              onClick={() =>
                handleIconClick(
                  "https://www.facebook.com/yourprofile",
                  "facebook"
                )
              }
              className={`footer-section_icons_box1 ${
                activeIcon === "facebook" ? "active" : ""
              }`}
            >
              <img src={facebook_bg_black_icon} alt="facebook_bg_icon" />
            </div> */}
            <div
              onClick={() =>
                handleIconClick(
                  "https://twitter.com/checkmateestd89?s=21&t=05qgsBzcSUiRfWiWUK5c2A",
                  "twitter"
                )
              }
              className={`footer-section_icons_box2 ${
                activeIcon === "twitter" ? "active" : ""
              }`}
            >
              <img src={twitter_bg_black_icon} alt="twitter_bg_icon" />
            </div>
            {/* <div
              onClick={() =>
                handleIconClick(
                  "https://www.instagram.com/yourprofile",
                  "instagram"
                )
              }
              className={`footer-section_icons_box3 ${
                activeIcon === "instagram" ? "active" : ""
              }`}
            >
              <img src={instagram_bg_black_icon} alt="instagram_bg_icon" />
            </div> */}

            <div
              onClick={() =>
                handleIconClick(
                  "https://www.youtube.com/yourprofile",
                  "youtube"
                )
              }
              className={`footer-section_icons_box4 ${
                activeIcon === "youtube" ? "active" : ""
              }`}
            >
              <img src={youtube_bg_black_icon} alt="youtube_bg_icon" />
            </div>

            <div
              onClick={() =>
                handleIconClick(
                  "https://www.linkedin.com/company/checkmate-estd-1988/",
                  "linkedIn"
                )
              }
              className={`footer-section_icons_box5 ${
                activeIcon === "linkedIn" ? "active" : ""
              }`}
            >
              <img src={linkedln_bg_black_icon} alt="Linkedln_bg_icon" />
            </div>
          </div>
          <Link to="/">
            <img src={footer_logo} alt="Footer Logo" className="footer_logo" />
          </Link>
        </div>
      </footer>
      <div className="footer_copyright_section">
        <hr className="footer_section_horizonatalline" />
        <div className="footer_secion_copyright_content">
          Copyrights 2024 - Checkmate Group. All rights reserved.
        </div>
      </div>
    </>
  );
};

export default Footer;
